import { has, orderBy } from 'lodash-es';

const loadedCategories = new Map();
const loadedTournaments = new Map();
const loadedTournamentRounds = new Map();
const loadedMarkets = new Map();
const loadedOutcomes = new Map();

function constructSpecifiersOrder(specifiers = []) {
  const specifier = specifiers?.[0]?.value;
  if (!specifier) return [0];

  if (specifier?.includes(':'))
    return [parseInt(specifier.split(':')[0]), parseInt(specifier.split(':')[1])];

  return [+specifier];
}

function mapSports(rawSports = []) {
  if (!rawSports.length) return new Map();

  const sports = new Map();
  const sportsList = [];

  for (const sport of rawSports) {
    sports.set(sport.id, sport);
    sportsList.push(sport.id);
  }

  return { sports, sportsList };
}

function mapCategories(rawCategories = []) {
  if (!rawCategories.length) return new Map();

  const categories = new Map();
  const categoriesList = [];

  for (const category of rawCategories) {
    categories.set(category.id, category);
    categoriesList.push(category.id);

    loadedCategories.set(category.id, category);
  }

  return { categories, categoriesList };
}

function mapTournaments(rawTournaments = []) {
  if (!rawTournaments.length) return new Map();

  const tournaments = new Map();
  const tournamentsList = [];

  for (const tournament of rawTournaments) {
    tournaments.set(tournament.id, tournament);
    tournamentsList.push(tournament.id);

    loadedTournaments.set(tournament.id, tournament);
  }

  return { tournaments, tournamentsList };
}

function mapTournamentRounds(rawTournamentRounds = []) {
  if (!rawTournamentRounds.length) return new Map();

  const tournamentRounds = new Map();
  const tournamentRoundsList = [];

  for (const tournamentRound of rawTournamentRounds) {
    tournamentRounds.set(tournamentRound.id, tournamentRound);
    tournamentRoundsList.push(tournamentRound.id);
  }

  return { tournamentRounds, tournamentRoundsList };
}

export function mapMarkets(rawMarkets = [], isLiveGame) {
  if (!rawMarkets.length) return new Map();

  const sortedMarkets = orderBy(rawMarkets, [
    ({ positionLive, positionPrematch }) => (isLiveGame ? positionLive : positionPrematch),
    ({ name }) => name,
  ]);

  const markets = new Map();
  const marketsPerSport = new Map();
  const marketsList = [];

  const outcomes = new Map();
  const outcomesList = [];

  for (const market of sortedMarkets) {
    if (!marketsPerSport.has(market.sportId)) marketsPerSport.set(market.sportId, new Map());

    const listOfOutcomes = new Map();

    const sortedOutcomes = orderBy(market.outcomes, 'position');
    for (const outcome of sortedOutcomes) {
      outcomes.set(outcome.id, { ...outcome, marketId: market.id });
      loadedOutcomes.set(outcome.id, outcomes.get(outcome.id));

      outcomesList.push(outcome.id);
      listOfOutcomes.set(outcome.id, 1);
    }

    const marketHasSpecialValue =
      sortedOutcomes?.some((outcome) => !!outcome?.specifiers?.length) ||
      !!market?.specifiers?.length;

    marketsPerSport.get(market.sportId).set(market.id, 1);
    marketsList.push(market.id);

    markets.set(market.id, {
      ...market,
      outcomesList: listOfOutcomes,
      hasSv: marketHasSpecialValue,
    });
    loadedMarkets.set(market.id, markets.get(market.id));
  }

  return { markets, marketsList, marketsPerSport, outcomes, outcomesList };
}

function mapMarketGroups(rawMarketGroups = [], isLive) {
  const marketGroups = new Map([
    ['all', { id: 'all', name: 'general_all', static: true }],
    ['favourite', { id: 'favourite', static: true }],
    ['bet_builder', { id: 'bet_builder', name: 'bet_builder', static: true }],
  ]);

  const orderMarketGroups = orderBy(
    rawMarketGroups ?? [],
    isLive ? 'positionLive' : 'positionPrematch',
  );

  for (const marketGroup of orderMarketGroups) marketGroups.set(marketGroup.id, marketGroup);

  return marketGroups;
}

export function sortAndFilterEventMarketOutcomes(outcomes, metaOutcomes = loadedOutcomes) {
  const sortedOutcomes = orderBy(
    [...outcomes].filter(([, o]) => (has(o, 'status') ? o.status : true)),
    [
      ([, { specifiers }]) => parseFloat(constructSpecifiersOrder(specifiers)?.join('')) || 0,
      ([, outcome]) =>
        outcome?.competitors
          ?.map((competitor) => competitor?.playerName || competitor?.teamName)
          .sort()
          .join(','),
      ([, { outcomeId }]) => {
        const mOutcome = metaOutcomes.get(outcomeId);
        return mOutcome?.position;
      },
    ],
  );

  return new Map(sortedOutcomes);
}

export function mapEventMarketOutcomes(outcomes, inStateOutcomes = null) {
  const eventMarketOutcomes = new Map();
  for (const outcome of outcomes) {
    const oldOutcome = inStateOutcomes?.get(outcome.id);

    eventMarketOutcomes.set(outcome.id, {
      ...outcome,
      ...(oldOutcome ? { odds: oldOutcome.odds } : {}),
    });
  }

  return eventMarketOutcomes;
}

export function mapEvents(rawEvents = [], promoOfferId = null, catalogId = null) {
  if (!rawEvents.length) return new Map();

  const events = new Map();
  const eventsList = [];
  const eventsToSubscribeOnOfferChanges = [];

  for (const event of rawEvents) {
    const categoryId = loadedTournaments.get(event.tournamentId)?.categoryId;
    const sportId = loadedCategories.get(categoryId)?.sportId;

    const eventMarkets = new Map();
    for (const market of event.markets)
      eventMarkets.set(market.id, {
        ...market,
        outcomes: mapEventMarketOutcomes(market.outcomes),
        numberOfOutcomes: loadedMarkets.get(market.marketId)?.outcomes?.length,
      });

    events.set(event.id, {
      ...event,
      sportId,
      categoryId,
      promoOfferId,
      catalogId,
      markets: eventMarkets,
    });
    eventsList.push(event.id);

    eventsToSubscribeOnOfferChanges.push({ id: event.id, version: event.version });
  }

  return { events, eventsList, eventsToSubscribeOnOfferChanges };
}

export function mapEvent(event, inStateEventMarkets = null) {
  const categoryId = loadedTournaments.get(event.tournamentId)?.categoryId;
  const sportId = loadedCategories.get(categoryId)?.sportId;

  const eventMarkets = new Map();
  for (const market of event.markets) {
    const inStateEventMarket = inStateEventMarkets?.get(market.id);

    eventMarkets.set(market.id, {
      ...market,
      outcomes: mapEventMarketOutcomes(market.outcomes, inStateEventMarket?.outcomes),
      numberOfOutcomes: loadedMarkets.get(market.marketId)?.outcomes?.length,
    });
  }

  return { ...event, sportId, categoryId, markets: eventMarkets };
}

function mapPromoOffers(rawPromoOffers = []) {
  if (!rawPromoOffers.length) return new Map();

  const promoOffers = new Map();
  const catalogs = new Map();
  let promoOfferList = [];
  let catalogList = [];

  let events = new Map();
  let eventsList = [];
  let eventsToSubscribeOnOfferChanges = [];

  for (const offer of rawPromoOffers) {
    promoOffers.set(offer.id, {
      id: offer.id,
      position: offer.position,
      name: offer.name,
      info: offer.info,
      type: offer.type,
      code: offer.code,
    });
    promoOfferList.push(offer.id);

    if (offer.catalogs) {
      for (const catalog of offer.catalogs) {
        catalogs.set(catalog.id, {
          id: catalog.id,
          position: catalog.position,
          name: catalog.name,
          promoOfferId: offer.id,
          code: catalog.code,
          info: catalog.info,
        });
        catalogList.push(catalog.id);

        const mappedEvents = mapEvents(catalog.events, offer.id, catalog.id);
        events = new Map([...events, ...mappedEvents.events]);
        eventsList = [...eventsList, ...mappedEvents.eventsList];
        eventsToSubscribeOnOfferChanges = [
          ...eventsToSubscribeOnOfferChanges,
          ...mappedEvents.eventsToSubscribeOnOfferChanges,
        ];
      }
    }
  }

  catalogList = orderBy(catalogList, [
    (catalogId) => {
      const catalog = catalogs.get(catalogId);
      return catalog?.position;
    },
    (catalogId) => {
      const catalog = catalogs.get(catalogId);
      return catalog?.name.toLowerCase();
    },
  ]);

  promoOfferList = orderBy(promoOfferList, [
    (offerId) => {
      const offer = promoOffers.get(offerId);
      return offer?.position;
    },
    (offerId) => {
      const offer = promoOffers.get(offerId);
      return offer?.name.toLowerCase();
    },
  ]);

  return {
    promoOffers,
    promoOfferList,
    catalogs,
    catalogList,
    events,
    eventsList,
    eventsToSubscribeOnOfferChanges,
  };
}

export function sortEvents(
  eventsList,
  events,
  sports,
  categories,
  tournaments,
  tournamentRounds,
  isLive,
  promoOffer,
  catalogs,
) {
  const positionProp = isLive ? 'positionLive' : 'positionPrematch';

  return orderBy(eventsList, [
    (eventId) => {
      if (!promoOffer?.size) return;

      const event = events.get(eventId);
      return promoOffer.get(event.promoOfferId)?.position;
    },
    (eventId) => {
      if (!promoOffer?.size) return;

      const event = events.get(eventId);
      return promoOffer.get(event.promoOfferId)?.name.toLowerCase();
    },
    (eventId) => {
      if (!catalogs?.size) return;

      const event = events.get(eventId);
      return catalogs.get(event.catalogId)?.position;
    },
    (eventId) => {
      if (!catalogs?.size) return;

      const event = events.get(eventId);
      return catalogs.get(event.catalogId)?.name.toLowerCase();
    },
    (eventId) => {
      const event = events.get(eventId);
      return sports.get(event.sportId)?.[positionProp];
    },
    (eventId) => {
      const event = events.get(eventId);
      return event.sportId;
    },
    (eventId) => {
      const event = events.get(eventId);
      return tournaments.get(event.tournamentId)?.[positionProp];
    },
    (eventId) => {
      const event = events.get(eventId);
      return categories.get(event.categoryId)?.[positionProp];
    },
    (eventId) => {
      const event = events.get(eventId);
      return categories.get(event.categoryId)?.name;
    },
    (eventId) => {
      const event = events.get(eventId);
      return categories.get(event.categoryId)?.id;
    },
    (eventId) => {
      const event = events.get(eventId);
      return tournaments.get(event.tournamentId)?.name;
    },
    (eventId) => {
      const event = events.get(eventId);
      return tournaments.get(event.tournamentId)?.id;
    },
    (eventId) => {
      const event = events.get(eventId);
      return tournamentRounds.get(event.tournamentRoundId)?.name;
    },
    (eventId) => {
      const event = events.get(eventId);
      return tournamentRounds.get(event.tournamentRoundId)?.id;
    },
    (eventId) => {
      const event = events.get(eventId);
      return event?.startsAt;
    },
  ]);
}

export function resetLocalState() {
  loadedCategories.clear();
  loadedTournaments.clear();
  loadedTournamentRounds.clear();
  loadedMarkets.clear();
  loadedOutcomes.clear();
}

export function checkEventFitmentInOffer({
  eventsList,
  isLive,
  timeFilters,
  selectedFilters,
  event,
}) {
  if (eventsList?.some((id) => id === event.id)) return false;

  if (!isLive && selectedFilters.time !== 'all') {
    const timeFilter = timeFilters[selectedFilters.time];
    const endDate = timeFilter.endDate ?? dayjs(timeFilter.date).endOf('day');

    if (!dayjs(event.startsAt).isBetween(timeFilter.date, endDate, null, '[]')) return false;
  }

  return (
    selectedFilters.id === 'all' ||
    selectedFilters.sport?.id === event.sportId ||
    selectedFilters.category?.id === event.categoryId ||
    selectedFilters.tournament?.id === event.tournamentId ||
    (selectedFilters.streamedEvents && event.streamContentTypes?.some((id) => id === 1))
  );
}

export function setupOffer(offer, isLive) {
  const { sports, sportsList } = mapSports(offer.sports);
  const { categories, categoriesList } = mapCategories(offer.categories);
  const { tournaments, tournamentsList } = mapTournaments(offer.tournaments);
  const { tournamentRounds, tournamentRoundsList } = mapTournamentRounds(offer.tournamentRounds);

  const { markets, outcomes, marketsPerSport, marketsList, outcomesList } = mapMarkets(
    offer.markets,
    isLive,
  );
  const marketGroups = mapMarketGroups(offer.marketGroups, isLive);

  let customObj = {};

  if (offer.promotedOffers) {
    customObj = { ...mapPromoOffers(offer.promotedOffers) };
  } else {
    customObj = { ...mapEvents(offer.events) };
  }

  return {
    ...(offer?.cursorId && { cursorId: offer.cursorId }),

    sports,
    sportsList,

    categories,
    categoriesList,

    tournaments,
    tournamentsList,

    tournamentRounds,
    tournamentRoundsList,

    markets,
    marketsPerSport,
    marketsList,
    outcomes,
    outcomesList,
    marketGroups,

    ...customObj,
  };
}
