const initGoogleTagManager = (gtmId = 'G-868SBRFFP7') => {
  window.dataLayer = window.dataLayer || [];
  const gtmScriptElement = document.createElement('script');
  gtmScriptElement.async = true;
  gtmScriptElement.type = 'text/javascript';
  gtmScriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
  document.body.appendChild(gtmScriptElement);

  pushToGoogleTagManager('js', new Date());
  pushToGoogleTagManager('config', gtmId);
};

function pushToGoogleTagManager() {
  window.dataLayer.push(arguments);
}

export { initGoogleTagManager, pushToGoogleTagManager };
