<template>
  <div
    v-if="bet"
    :class="[
      'bet-details',
      {
        'bet-details--with-app-header': store.config?.header?.enabled,
        'bet-details--with-extra-padding': !!betslipStore.activeSelectionsCount,
      },
    ]">
    <div class="bet-details__header">
      <div
        v-if="!hideBackButton"
        class="bet-details__prev-button"
        @click="store.toggleBetPreview">
        <Icon icon="n-i-arrow-left-d" />
      </div>
      <div class="bet-details__header-info">
        <div class="bet-details__title">
          {{ betDetailsTitle }}
        </div>
        <div class="bet-details__subtitle">
          {{ betPlacedAt }}
          <StatusBadge
            :type="aggregatedStatus"
            :status="betStatusAndPayout"
            size="small" />
        </div>
      </div>
      <!-- <div class="bet-details__header-actions">
        <Icon icon="n-i-share" />
      </div> -->
    </div>

    <BetDetailsBody
      :bet="bet"
      :available-events="availableEventsList"
      show-status
      show-results />

    <div
      v-if="bet.cashout"
      class="bet-details__cashout">
      <div class="bet-details__cashout-info">
        <div class="bet-details__cashout-label">
          {{ store.getTranslation('cashout_available') }}:
        </div>
        <div class="bet-details__cashout-value">
          {{ formatNumber(bet.cashout.winnings.total, bet.currency) }}
        </div>
      </div>

      <Button
        v-if="!cashoutIncludesErrorCode"
        class="bet-details__cashout-action"
        :disabled="!bet.cashout.allowed"
        variant="secondary"
        @click="handleCashout(bet.betProfileId, bet.cashout)">
        <span v-if="!bet.cashout.processing">{{ store.getTranslation('cashout') }}</span>
        <Spinner
          v-else
          class="mini" />
      </Button>
      <div
        v-else
        :title="store.getTranslation('cashout_unavailable')"
        class="bet-details__cashout-unavailable">
        <Icon
          icon="n-i-lock-a"
          size="xl" />
        <span>{{ store.getTranslation('cashout_unavailable') }}</span>
      </div>
    </div>

    <div class="bet-details__actions">
      <Button
        v-if="hasRebet"
        class="bet-details__rebet"
        block
        variant="primary"
        @click="handleRebetAction"
        >{{ store.getTranslation('general_rebet') }}</Button
      >
    </div>

    <div class="bet-details__bet-id-section">
      <div class="bet-details__bet-id">
        <div class="bet-details__bet-id-label">{{ store.getTranslation('bet_id') }}:</div>
        <div class="bet-details__bet-id-value">{{ betId }}</div>
      </div>

      <Clipboard
        class="bet-details__bet-id-copy"
        :source="betId"
        @copy="handleClipboardCopy" />
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref, toRef, nextTick, computed } from 'vue';
import { useStore } from '@/stores/store.js';
import { useBetslipStore } from '@/stores/BetslipStore';

import useBetDetails from '@/composables/bet-details/useBetDetails';
import BetDetailsBody from '@/components/mobile/common/BetDetailsBody.vue';
import Icon from '@/components/common/Icon.vue';
import Button from '@/components/common/Button.vue';
import Clipboard from '@/components/common/Clipboard.vue';
import StatusBadge from '@/components/common/StatusBadge.vue';
import Spinner from '@/components/common/Spinner.vue';
import { formatNumber } from '../../../utils/helpers';

const props = defineProps({
  bet: {
    type: [Object, null],
    required: true,
    default: null,
  },
  showRebetAction: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideBackButton: {
    type: Boolean,
    required: false,
    default: false,
  },
});

defineEmits(['cancelBet']);
const store = useStore();
const betslipStore = useBetslipStore();

const betRef = toRef(props, 'bet');
const {
  betDetailsTitle,
  aggregatedStatus,
  betStatusAndPayout,
  handleClipboardCopy,
  betPlacedAt,
  betEvents,
  handleCashout,
  systems,
  totalCombinations,
  betId,
  cashoutIncludesErrorCode,
} = useBetDetails(betRef);

const availableEvents = ref([]);
const availableEventsList = computed(() => {
  return availableEvents.value.map(({ id }) => id);
});

const hasRebet = computed(() => {
  return (
    props.showRebetAction && availableEvents.value.length > 0 && aggregatedStatus.value === 'OPEN'
  );
});

const handleRebetAction = async () => {
  availableEvents.value = await store.getAvailableEvents(betEvents.value);

  if (!availableEvents.value.length) {
    store.displayRebetUnavailableMessage();
    return;
  }

  const rebetSuccess = await store.rebet({
    bet: props.bet,
    events: availableEvents.value,
    systems,
    totalCombinations,
    allEventsAvailable: availableEvents.value.length === betEvents.value.length,
  });

  if (!rebetSuccess) return;

  store.setSelectedView('betslip');

  nextTick(() => store.subscribeBetEvents(availableEvents.value));
};

onBeforeMount(async () => {
  availableEvents.value = await store.getAvailableEvents(betEvents.value);

  if (!availableEvents.value.length) return;

  store.subscribeBetEvents(availableEvents.value);
  for (const event of availableEvents.value ?? []) {
    if (!event?.detailedPlayStatus) continue;
    for (const [id] of store.betDetailsSelections) {
      if (event.id === +id)
        store.betDetailsSelections.get(id).detailedPlayStatus = event.detailedPlayStatus;
    }
  }
});
</script>

<style lang="scss">
:root {
  --bet-details-clipboard-notification-bg-color: var(--primary);
  --bet-details-clipboard-notification-color: var(--text-secondary-1);
  --bet-details-bg-color: var(--background-1);
  --bet-details-color: var(--text-primary-1);
  --bet-details-header-bg-color: var(--background-1);
  --bet-details-header-color: var(--text-primary-1);
  --bet-details-header-subtitle-color: var(--text-primary-2);
  --bet-details-prev-button-color: var(--text-primary-2);
  --bet-details-cashout-area-bg-color: var(--card-section-2);
  --bet-details-bet-id-area-bg-color: var(--card-section-2);
  --bet-details-footer-bg-color: var(--card-background);
  --bet-details-footer-height: 4.5625rem;
  --bet-details-footer-close-color: var(--primary);
}
</style>

<style scoped lang="scss">
.bet-details {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: var(--bet-details-bg-color);
  color: var(--bet-details-color);
  padding: 0 0 var(--bet-details-footer-height);
  @include z-index(bet-details);

  &--with-app-header {
    height: calc(100vh - var(--mob-app-header-height));
  }

  &--with-extra-padding {
    padding-bottom: 7rem;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem;
    height: 4.25rem;
    background: var(--bet-details-header-bg-color);
    color: var(--bet-details-header-color);
  }

  &__header-info {
    flex: 1;
  }

  &__prev-button,
  &__header-actions .icon {
    font-size: 14px;
    padding: var(--gap-base);
    color: var(--bet-details-prev-button-color);
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--bet-details-header-subtitle-color);
    margin-top: 0.25rem;
  }

  &__cashout {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    background-color: var(--bet-details-cashout-area-bg-color);

    &-info {
      flex: 1;
      font-size: 12px;
    }

    &-label {
      color: var(--text-primary-2);
    }

    &-value {
      color: var(--primary);
      font-weight: 500;
    }

    &-action {
      min-width: 6.4569rem;
      font-size: 14px;
      font-weight: 500;
      border-color: var(--cashout-button-border-color);
      background: var(--cashout-button-bg-color);

      .btn {
        padding: 11px 16px;
      }

      &.btn--disabled {
        opacity: 0.3;
      }
    }

    &-unavailable {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      overflow: hidden;
      padding: var(--gap-10) var(--gap-16);
      gap: var(--gap-base);
      font-size: var(--font-small);
      color: var(--text-primary-2);
      background-color: var(--button);
      border-radius: var(--border-radius-xs);

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &__actions {
    background-color: var(--card-background);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &:has(.btn) {
      padding: 0.75rem;
    }
  }

  &__bet-id-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background-color: var(--bet-details-bet-id-area-bg-color);
    padding: 0.75rem;
  }

  &__bet-id {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;

    &-label {
      font-size: 12px;
      font-weight: 400;
      color: var(--text-primary-2);
    }

    &-value {
      font-size: 12px;
      font-weight: 500;
    }

    &-copy {
      width: 2rem;
      height: 2rem;
      padding: 10px;
      display: flex;
      align-items: center;
      background-color: var(--btn-bg);
      border-radius: var(--border-radius-xs);
    }
  }

  &__bet-actions {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: var(--gap-16);
  }

  &__action-button {
    font-size: 0.875rem;
    text-transform: uppercase;
    padding: var(--gap-12) var(--gap-base);
    color: var(--bet-details-footer-close-color);
    text-align: right;
  }
}
</style>
